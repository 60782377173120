import { IconSystemTickOutline } from '@moonpig/launchpad-assets'
import { PrimaryButton, Flex, Box } from '@moonpig/launchpad-components'
import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useBasket } from '../../contexts/basket'
import { useProductsLocaleText } from '../../locale'

const slideInFade = keyframes`
  20%, 79% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(0%);
  }
`

const StyledIconContainer = styled(Box)`
  position: absolute;
  margin-left: -160px;
  top: -1px;
  transform: translateX(-120%);
  opacity: 0;
  &.is-animating {
    animation: ${slideInFade} 2000ms ease-in-out forwards;
  }
`

export const ButtonWithConfirmation = ({
  primaryButton,
  basketState,
}: {
  primaryButton: {
    text: string
    onClick: () => void
  }
  basketState: boolean
}) => {
  const [isAnimating, setIsAnimating] = React.useState(false)
  const [{ success }] = useBasket()
  const [hasBeenClicked, setHasBeenClicked] = React.useState(false)

  const { text, onClick } = primaryButton
  const [copy, setCopy] = React.useState(text)

  const localiseText = useProductsLocaleText()
  const successCopy = localiseText('confirmation_button.added_to_basket')

  useEffect(() => {
    if (success && hasBeenClicked) {
      setIsAnimating(true)
      setCopy(successCopy)
      setHasBeenClicked(false)
    }
  }, [success, successCopy, hasBeenClicked])

  return (
    <PrimaryButton
      data-testid="add-to-basket-with-confirmation-button"
      width={1}
      onClick={() => {
        onClick()
        setHasBeenClicked(true)
      }}
      disabled={basketState}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        position="relative"
        width="auto"
        display="inline-flex"
      >
        <StyledIconContainer
          data-testid="add-to-basket-cta-confirmation-icon"
          onAnimationEnd={() => {
            setIsAnimating(false)
            setCopy(text)
          }}
          className={!isAnimating ? '' : 'is-animating'}
        >
          <IconSystemTickOutline width={26} />
        </StyledIconContainer>
        <Box>{copy}</Box>
      </Flex>
    </PrimaryButton>
  )
}
